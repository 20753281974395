<template>
  <div class="home-container">
    <div class='sc-tops'>
      <div class='top-center'>
        <div class="list-img">
          <img :src="data.picture">
        </div>
        <div class="list-info">
          <span>{{ data.name }}</span>
          <span style="margin-bottom: 0.1rem;margin-top: 0.15rem;    background: none;
                 text-align: left;
                 font-size: 0.35rem;
                 font-weight: 700;"><a v-if="data.receiveType == '0'">免费领取</a></span>
          <span style="    color: #999999;
         font-size: 0.35rem; text-decoration: line-through;">
            {{ data.price }}
          </span>
        </div>

      </div>
      <div style="  border-radius: 0 0 0.6rem 0.6rem;    background: rgb(255, 255, 255);
    width: 93%;
    margin: 0px auto;
    position: relative;
    height: 2.3rem;">
        <div class="sc-step">
          <img style="float: left;" src="../../assets/img/u89.png">
          <img style="float: right;" src="../../assets/img/u90.png">

        </div>
        <div class="sc-step-loop">
          <span></span>
        </div>
        <div style="
    font-size: 0.32rem;display: flex;    width: 78%;margin-left: 12%;justify-content: space-between;text-align: center;">
          <span>免费兑换</span>
          <span> 同一号码登录 </span>
        </div>

      </div>

    </div>
    <!-- <div class="sc-text">
      温馨提示:同一微信号每月限领取一张代金券。
    </div> -->
    <div class="sc-text" style="margin-top:2.65rem;margin-bottom: 1.5rem;    border-radius: 0.6rem 0.6rem 0 0;">
      <div v-html="data.content"></div>

    </div>
    <div v-show="!islogin" class="sc-button" @click="clickdetial">立即开通 ·生活会员(10元/月)</div>
    <div v-show="islogin" class="sc-button" @click="clickdetial">立即兑换</div>
  </div>
</template>

<script>
import {
  getReceiveCoupon,
  getRightsById,
  recieveRights,
  getCouponInfo
} from '@/api/user'
import {
  Dialog
} from 'vant';
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'

export default {
  name: 'home',

  props: {},
  data() {
    return {

      active: 0, //被激活的列表
      channels: [], //频道列表
      isChannelEditShow: false, //频道编辑层
      navlist: [{
        name: '未使用'
      },
      {
        name: '已使用'
      },
      {
        name: '已失效'
      }

      ],
      list: [],
      loading: false,
      detailid: '',
      data: [],
      finished: false,
      refreshing: false,
      islogin: false, //判断用户是否登录
    }
  },

  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {
    if (this.$store.state.user.token) {
      this.islogin = true

    }
    this.detailid = this.$route.params.detailid
    console.log(this.$route.params.detailid)
    if (this.$route.params.detailid === undefined) {
      this.detailid = getItem("detailid1")
    }
    setItem('detailid1', this.detailid)
    this.data = this.detailid
    this.onLoad()
    this.record({
      "type": 7,
      "resourceId": this.detailid
    })
  },
  methods: {
    async onLoad() {
      let params = {
        id: this.detailid
      }
      console.log(params)
      const {
        data
      } = await getRightsById(params)
      this.data = data.data
      this.$forceUpdate()
    },
    async clickdetial() {
      let params = {
        //   "userId":12,
        "id": this.detailid,
      }
      if (this.detailid == null) {
        Dialog.alert({
          title: '',
          message: '请重新选择互联网权益',
        }).then(res => {
          this.$router.push({
            name: 'home'
          })
        })
      }
      const {
        data
      } = await recieveRights(params)



      if (data.code == '100102' || data.code == '100103' ||
        data.code == '100104' ||
        data.code == '100105') {
        Dialog.alert({
          title: '',
          showCancelButton: true,
          confirmButtonText: '立即开通',
          message: '您尚未开通会员，无法领取！',
        }).then(() => {
          if (getItem('localtion').ltAreaCode == 170) {
            Dialog.alert({
              message: '当前未开启线上订购业务，敬请期待!',
            })
          } else {
            this.$router.push({
              name: 'member',
              params: '',
            })
          }

        });
      }

      else {
        Dialog.alert({
          title: '',
          confirmButtonText: '确定',
          message: data.msg,
        })
      }



    }


    // if(data.code == )


    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style lang="less">
img {
  pointer-events: none;
}

.sc-button {
  background-image: url(../../assets/img/scbutton.png);
  width: 90%;
  margin: 0 auto;
  position: fixed;
  bottom: 0.2rem;
  background-size: 100% 100%;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.38rem;
  padding: 0.4rem;
}

p {
  margin-bottom: 0.2rem;
}

.sc-text {
  font-size: 0.32rem;
  background: #fff;
  width: 93%;
  margin: 0 auto;
  margin-top: 3rem;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.2rem;
  color: #333333;
}

.sc-step {
  z-index: 999;
  /* position: absolute; */
  margin: 0 auto;
  // display: flex;
  width: 73%;
  // justify-content: space-between;

  img {
    display: block;
    /* float: left; */
    width: 1.2rem;
    margin: 0.2rem 0;
  }
}

.sc-step-loop {
  position: absolute;
  height: 0.1rem;
  background: #ffe0e5;
  border-radius: 0.5rem;
  width: 49%;
  /* margin: 0px auto; */
  /* bottom: 1.05rem; */
  top: 0.7rem;
  left: 2.4rem;

  span {
    display: block;
    width: 100%;
    height: 100%;
    background: #2ca7ee;
  }
}

.home-container {
  .sc-tops {
    background-image: url(../../assets/img/sctop.png);
    width: 100%;
    height: 2.7rem;
    padding-top: 0.5rem;
    background-size: 100% 100%;

    .top-center {
      display: flex;
      padding: 0.5rem;
      box-sizing: border-box;
      margin: 0 auto;
      background: #fff;
      width: 93%;

      img {
        display: block;
        width: 1.8rem;
        height: 2rem;
      }

      .list-info {
        padding-left: 0.5rem;

        span {
          display: block;
          font-size: 0.3rem;

          &:nth-child(1) {
            font-size: 0.4rem;
            color: #333333;
            font-family: SourceHanSansCN-Regular;
          }

          &:nth-child(2) {
            padding: 0.1rem 0;
            font-size: 0.3rem;
            background: #ffe9e9;
            border-radius: 0.48rem;
            text-align: center;
            margin: 0.28rem;
            width: 2.5rem;
            color: #0869ab;
            margin-left: 0;
          }

          &:nth-child(3) {
            font-size: 0.3rem;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
